@font-face {
  font-family: 'Moret';
  font-weight: 600;
  font-display: swap;
  src: url('/public/fonts/Moret/font.woff2') format('woff2'), url('/public/fonts/Moret/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica';
  font-display: swap;
  src: url('/public/fonts/Helvetica/Helvetica.ttf');
}

@font-face {
  font-family: 'Helvetica';
  font-weight: bold;
  font-display: swap;
  src: url('/public/fonts/Helvetica/Helvetica-Bold.ttf')
}

.font-moret {
  font-family: 'Moret';
}

.font-helvetica {
  font-family: 'Helvetica';
}
