.modal-width {
  min-height: 200px !important;
}

.percentage-step {
  position: relative;
}

.slider-rail {
  position: absolute;
  width: 99%;
  height: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.slider-track {
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0%;
  transform: translate(0%, -50%);
  top: 50%;
  border-radius: 5px;
}

.slider-thumb {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.steps {
  position: absolute;
  left: 0%;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount-percentage {
  cursor: pointer;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.amount-percentage:first-child {
  align-items: start;
  padding-left: 0;
}

.amount-percentage:last-child {
  align-items: end;
  padding-right: 0;
}

.price-percentage {
  border: 2px double transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fda9ff, #4dffe5);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 10px;
  height: 10px;
}

.amount-percentage.active {
  position: absolute;
}

.amount-percentage.active .price-percentage {
  width: 18px;
  height: 18px;
}

.amount-percentage.selected .price-percentage {
  background-image: linear-gradient(138.01deg, #fda9ff 4.74%, #4dffe5 100%),
    radial-gradient(circle at top left, #fda9ff, #4dffe5);
}

.percentage-text {
  width: 10px;
}

.percentage-text:first-child {
  justify-content: flex-start;
}

.percentage-text:last-child {
  justify-content: flex-end;
}

.modal-tab {
  width: 100%;
  height: 1px;
  background: #dfefff;
}

.modal-tab.active {
  height: 5px;
  background: linear-gradient(98.2deg, #fda9ff -6.64%, #4dffe5 106.64%);
}

input[type='range'] {
  opacity: 0;
  z-index: 100;
}

input[type='range']::-webkit-slider-thumb:hover {
  cursor: grab;
}

input[type='range']::-webkit-slider-thumb:active {
  cursor: grabbing;
}

input[type='range']:hover + .bubble-container {
  display: block !important;
}

.bubble-container {
  position: absolute;
  width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: none !important;
}

.bubble {
  background: linear-gradient(98.2deg, #fda9ff -6.64%, #4dffe5 106.64%);
  padding: 5px 8px;
  position: absolute;
  border-radius: 4px;
  transform: translateX(-50%);
  top: 15px;
}

.bubble::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  top: 5px;
  border: 4px solid transparent;
  border-top: 0;
  border-bottom: 5px solid #fda9ff;
  transform: translate(-50%, calc(-100% - 5px));
}

.modal-close {
  position: absolute;
  top: 40px;
  right: 15px;
  z-index: 10;
}

.modal-base-close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
}

.wallet-modal-layer {
  bottom: 60px !important;
}

.wallet-modal-layer-left {
  left: 40px !important;
}

.base-dialog-content {
  min-width: 480px !important;
}

.base-dialog-content-qr {
  min-width: 520px !important;
}

.proposal-detail-modal {
  width: 100%;
  max-width: 800px !important;
}

.proposal-vote-modal {
  width: 100%;
  max-width: 500px !important;
}

.proposal-vote-modal.pending {
  max-width: 380px !important;
}

.delegate-modal {
  width: 100%;
  max-width: 400px !important;
  transition: 00.3s;
}

.redelegate-modal {
  min-height: 500px !important;
}

.validator-modal {
  width: 100%;
  max-width: 680px !important;
}

.validators-list {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s;
  z-index: -1;
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 3px);
}

.validators-list.open {
  max-height: 310px;
  z-index: 10;
  opacity: 1;
}

.validator-search {
  background: transparent;
  border: none !important;
  outline: none;
  font-family: 'Helvetica';
}

.validators {
  height: calc(246px);
  overflow: auto;
  display: block !important;
}

@media (max-width: 640px) {
  .modal-layer {
    width: 100%;
  }

  .modal-width,
  .base-dialog-content,
  .base-dialog-content-qr {
    min-width: 100% !important;
  }
}
