@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.navbar {
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 20px;
  height: 60px;
  position: fixed;
  overflow: auto;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 3px 3px -2px, rgba(255, 255, 255, 0.14) 0px 3px 4px 0px,
    rgba(255, 255, 255, 0.12) 0px 1px 8px 0px;
  transition: all 0.3s ease-in-out;
}

.navbar.light {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
}

.sidenav-menus .menu-tab {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background: linear-gradient(180.14deg, #fda9ff 0.12%, #c9b8ff 27.33%, #4dffe5 99.92%);
  width: 5px;
  height: calc(100% / 6);
  transition: ease-in 0.3s;
}

.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.umee-logo {
  display: flex;
}

.umee-logo-img {
  width: 120px;
}

.nav-link {
  margin: 0 20px;
}

.nav-link:hover {
  color: white;
}

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.navbar.open {
  top: 90px;
  opacity: 1;
}

.connect-wallet-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
}

.external-link {
  margin-top: -4px;
}

.mobile-menu {
  min-height: calc(100vh - 60px) !important;
}

@media (max-width: 568px) {
  .umee-logo-img {
    width: 120px;
  }

  .navbar {
    height: 60px;
  }

  .mobile-menu {
    min-height: calc(100vh - 60px) !important;
  }
}