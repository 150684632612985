.indicators {
  position: absolute;
  width: 100%;
  left: 0;
  height: 21px;
  bottom: calc(100%);
  transform: translateY(-50%);
  z-index: -1;
}

.indicator {
  position: absolute;
  top: 0;
  transform: translateX(-100%);
}

.indicator-light::before {
  position: absolute;
  content: '';
  right: 0;
  top: 100%;
  height: 20px;
  width: 0px;
  border: 1px solid var(--umee-mid-grey-on-navy);
}

.indicator-dark::before {
  position: absolute;
  content: '';
  right: 0;
  top: 100%;
  height: 20px;
  width: 0px;
  border: 1px solid white;
}

.indicator-80 {
  left: 80%;
}

.indicator-100 {
  left: 100%;
}

.progress-bar {
  height: 5px;
  border-radius: 5px;
  position: relative;
}

.progress-bar-value {
  position: absolute;
  left: 0;
  height: calc(100%);
  border-radius: 5px;
  background: linear-gradient(90.01deg, #f386ff 0%, #43e0fd 100%);
  cursor: pointer;
  transition: ease-in-out 1s;
}

.progress-bar-value::before {
  position: absolute;
  left: 100%;
  transform: translateX(-50%);
  top: 30px;
  content: attr(aria-label);
  font-size: 11px;
}

.progress-bar-value::after {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 100%;
  top: 10px;
  transform: translate(-50%);
}

.progress-bar-value::before,
.progress-bar-value::after {
  pointer-events: none;
  opacity: 1;
}

.borrowed-value {
  transform: translate(-50%, -50%);
}

@media (max-width: 568px) {
  .progress-bar-value::before {
    top: 20px;
  }
}
