.footer-logo {
  width: 160px;
}

.footer-social-link {
  width: 35px;
  height: 35px;
  background-image: linear-gradient(#070927, #070927), linear-gradient(#464869, #464869);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 2px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

.footer-social-link:hover {
  background-image: linear-gradient(#070927, #070927), linear-gradient(90deg, #fda9ff, #c9b8ff, #4dffe5);
}

.footer-menus-title {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  -o-border-image: linear-gradient(90deg, #fda9ff, #c9b8ff, #4dffe5) 2;
  border-image: linear-gradient(90deg, #fda9ff, #c9b8ff, #4dffe5) 2;
  display: inline;
  padding-bottom: 2px;
}

.footer-menu:hover {
  color: white;
}

.footer-left {
  width: 30%;
}

.footer-menus-content {
  width: 40%;
}

@media (max-width: 1024px) {
  .footer-menus-content {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .footer-left,
  .footer-menus-content {
    width: 100%;
  }
}
