:root {
  --size: 1.3rem;
  --ray-size: calc(var(--size) * -0.38);
  --offset-orthogonal: calc(var(--size) * 0.75);
  --offset-diagonal: calc(var(--size) * 0.55);
}

.toggle {
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.38) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: white;
  transition: all 500ms;
}

.toggle.light {
  transform: scale(0.6) rotate(90deg);
  box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}
