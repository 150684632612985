.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 35px;
}

.loader {
  background: linear-gradient(#E6B5FA, #43E0FD);
  /* Show only 10px from the border */
  -webkit-mask:radial-gradient(farthest-side,transparent calc(100% - 6px),#fff 0);
          mask:radial-gradient(farthest-side,transparent calc(100% - 6px),#fff 0);
  width: 100px;
  height: 100px;
  border-radius: 50%;  
  opacity: 0;
  animation: rot 2s linear infinite, bounceIn .75s cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes rot {
  0% { transform: rotate(0deg); opacity: 0; }
  20% { opacity: 1 }
  75% { opacity: .7; }
  100% { transform: rotate(360deg); opacity: .5 }
}

@keyframes bounceIn {
  0%   { transform: scale(.8,1)     translateY(0); }
  10%  { transform: scale(1.1,.95)  translateY(0); }
  30%  { transform: scale(.95,1.1)  translateY(0); }
  50%  { transform: scale(1.05,.98) translateY(0); }
  57%  { transform: scale(1,1)      translateY(0); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}