button:not(:disabled) .primary-btn:hover {
  background-image: var(--umee-gradient-inverse);
}

button:not(:disabled) .secondary-btn:hover {
  border: double 2px transparent;
  background-image: linear-gradient(white, white), var(--umee-gradient);
  background-clip: padding-box, border-box;
  background-origin: border-box;
}

button:not(:disabled) .secondary-btn-dark:hover {
  background-image: linear-gradient(var(--umee-color-primary), var(--umee-color-primary)), var(--umee-gradient) !important;
}

.secondary-btn {
  min-width: 90px !important;
  min-height: 35px !important;
}

@media (max-width: 640px) {
  .secondary-btn {
    min-width: 60px !important;
  }
}
