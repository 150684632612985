.row-container {
  transition: 0.3s ease-out;
}

.row-container > div:focus {
  box-shadow: none !important;
  outline: none !important;
}

.row-container:hover {
  transition: 0.05s ease-in;
  background-color: var(--umee-full-grey1);
}

.row-container-dark:hover {
  background-color: #2A2C4E !important;
}

.row-content > div:first-child {
  padding-left: 5px;
}

.row-content > div:last-child {
  padding-right: 5px;
}
